.nav-smile svg:nth-child(2) {
  display: none;
}

.nav-smile:hover svg:nth-child(2) {
  display: block;
}

.nav-smile:hover svg:first-child {
  display: none;
}
