#root {
  height: 100%;
  width: 100%;
}

.verify-input::placeholder {
  text-align: center;
}

.shake {
  animation: shake 1.7s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  4%,
  46%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  5%,
  45% {
    transform: translate3d(-1px, 0, 0);
  }

  10%,
  40% {
    transform: translate3d(2px, 0, 0);
  }

  15%,
  25%,
  35% {
    transform: translate3d(-4px, 0, 0);
  }

  20%,
  30% {
    transform: translate3d(4px, 0, 0);
  }
}

.input,
.button {
}

.input:focus,
.button:focus {
}
